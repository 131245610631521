import React from 'react'

const SuperTitle = ({ title = 'Contra Revolución' }: { title?: string }) => {
    return (
        <h1 className='text-2xl lg:text-3xl text-gray-700 unifrakturmaguntia-regular'>
            {title}
        </h1>
    )
}

export default SuperTitle
