import React from 'react'
import { Link } from "react-router-dom";
import { Title } from "../../../components";
import { GiJerusalemCross } from "react-icons/gi";

const ListMap = ({ data, heading }: ListProps) => {
    return (
        <div className='w-full md:w-1/2'>
            <Title className="mb-8">{heading}</Title>
            <div className="flex flex-col mb-16 w-full">
                {data?.map((post) => (
                    <Link
                        to={`./post/${post?.sys?.id}`}
                        key={post?.title + post?.sys?.id}
                        className='mb-4 md:mb-0'
                    >
                        <div className='flex align-middle'>
                            <GiJerusalemCross className='mr-1 w-4 h-4 flex-shrink-0' />
                            <span className='underline underline-offset-1 -mt-1 mb-2 hover:text-pink-600'>
                                {post.title}
                            </span>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    )
}

export default ListMap

interface ListProps {
    data: any[]
    heading: string
}
