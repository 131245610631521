import React from 'react'

const ImageSkeletonCard = () => {
    return (
        <div className="border border-1 border-gray-200 w-full h-96 rounded flex flex-col justify-items-center p-3 animate-pulse">
            <div className="w-full h-full bg-gray-200 rounded-xl" >
            </div>
        </div>
    )
}

export default ImageSkeletonCard
