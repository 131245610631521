import { ApolloClient, InMemoryCache } from '@apollo/client';

//  API de Contentful 
export const contenfulClient = new ApolloClient({
    cache: new InMemoryCache(),
    uri: 'https://graphql.contentful.com/content/v1/spaces/chrqkno1s6u9',
    headers: {
        // contentful token
        'Authorization': 'Bearer b2It52dk_9bYBrQJHeFjijhju7m_t_9AQzfR6FZuT-w'
    }
});