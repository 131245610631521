import { gql } from "@apollo/client";

export const GET_AUTHOR_COLLECTION = gql`
query AuthorCollection { 
  authorCollection {
    items {
      sys {
        id
      }
      name
      bio {
        json
      }
      avatar {
        url
      }
      xProfileUrl
    }
  }
}`

export const GET_AUTHOR = gql`
query GET_AUTHOR($id: String!) {
    author(id: $id) {
      sys {
        id
      }
      name
      bio {
        json
      }
      avatar {
        url
      }
      xProfileUrl
    }
  }`