import { useMutation, UseMutationOptions } from "react-query";
import { db } from "firebaseConfig/config";
import { doc, runTransaction } from 'firebase/firestore';

const incrementViewCount = async (articleId: string) => {
    const articleRef = doc(db, 'articles', articleId);

    return runTransaction(db, async (transaction) => {

    const articleSnapshot = await transaction.get(articleRef);
    if (!articleSnapshot.exists()) {
      // Document doesn't exist, set initial values
      transaction.set(articleRef, { views: 1, reactions: 0 });
    } else {
      const currentViews = articleSnapshot.data().views || 0;
      transaction.update(articleRef, { views: currentViews + 1 });
    }
  });
}


export const useIncrementViewCount = (
  options?: UseMutationOptions<void, unknown, string>
) => {
  return useMutation(incrementViewCount, options);
};

export default useIncrementViewCount
