import React from 'react'

const ResponsiveWrap = ({ children, className }: ResponsiveWrapProps) => {
    return (
        <div className={`w-full ${className}`}>
            <div className="w-10/12 md:w-4/5 lg:w-3/4 xl:w-3/4 2xl:w-2/3 mx-auto">
                {children}
            </div>
        </div>
    )
}

export default ResponsiveWrap

interface ResponsiveWrapProps {
    children: React.ReactNode
    className?: string
}