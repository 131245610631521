import React from 'react'
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import {
    EmailShareButton,
    FacebookShareButton,
    // HatenaShareButton,
    // InstapaperShareButton,
    // LineShareButton,
    LinkedinShareButton,
    // LivejournalShareButton,
    // MailruShareButton,
    // OKShareButton,
    // PinterestShareButton,
    // PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    // ViberShareButton,
    // VKShareButton,
    WhatsappShareButton,
    // WorkplaceShareButton
} from "react-share";
import {
    FacebookIcon,
    // MessengerIcon,
    TelegramIcon,
    TwitterIcon,
    LinkedInIcon,
    RedditIcon,
    WhatsAppIcon,
    TumblrIcon,
    // RenderIf
} from "../";
import { MdEmail } from "react-icons/md";
import { IArticleShares } from "types/firebaseTypes";
import { useIncrementShareCount } from "hooks";


interface ReactShareProps {
    url: string
    articleViewsAndShares?: IArticleShares
}

const ReactShare = ({ url, articleViewsAndShares }: ReactShareProps) => {
    const queryClient = useQueryClient()
    const { id } = useParams<{ id: string }>()
    const { mutateAsync } = useIncrementShareCount({
        onSuccess: () => queryClient.invalidateQueries(['articleData', id])
    })
    const isDevelopment = process.env.NODE_ENV === 'development'

    const handleShareClick = (sharedChannel: string) => {
        mutateAsync({ articleId: id, sharedChannel })
    }

    const totalShares = Object.entries(articleViewsAndShares || {})
        .filter(([key]) => !["reactions", "views", "comments"].includes(key))
        .reduce((acc, [, value]) => acc + value, 0);

    return (
        <div className='-ml-6 md:ml-0 flex flex-col items-center'>
            {/* New Element for Total Shares */}
            <div className="bg-black text-white p-1 md:p-2 rounded text-center mb-2 md:mb-4 text-xs md:text-sm">
                {totalShares} Envíos
            </div>

            <div onClick={() => handleShareClick('TwitterShared')}>
                <TwitterShareButton url={isDevelopment ? 'https://www.google.com/' : url} className='relative'>
                    <div className="w-8 h-8 bg-black rounded-full" style={{ color: 'rgb(239, 243, 244)' }}>
                        <TwitterIcon className="cursor-pointer hover:opacity-100" />
                    </div>
                </TwitterShareButton>
            </div>

            <div onClick={() => handleShareClick('TelegramShared')}>
                <TelegramShareButton url={isDevelopment ? 'https://www.google.com/' : url} className='relative'>
                    <TelegramIcon className="cursor-pointer hover:opacity-100" />
                </TelegramShareButton>
            </div>

            <div onClick={() => handleShareClick('RedditShared')}>
                <RedditShareButton url={isDevelopment ? 'https://www.google.com/' : url} className='relative'>
                    <RedditIcon className="cursor-pointer hover:opacity-100" />
                </RedditShareButton>
            </div>

            <div onClick={() => handleShareClick('FacebookShared')}>
                <FacebookShareButton url={isDevelopment ? 'https://www.google.com/' : url} className='relative'>
                    <FacebookIcon className="cursor-pointer hover:opacity-100" />
                </FacebookShareButton>
            </div>

            <div onClick={() => handleShareClick('LinkedinShared')}>
                <LinkedinShareButton url={isDevelopment ? 'https://www.google.com/' : url} className='relative'>
                    <LinkedInIcon className="cursor-pointer hover:opacity-100" />
                </LinkedinShareButton>
            </div>


            <div onClick={() => handleShareClick('WhatsappShared')}>
                <WhatsappShareButton url={isDevelopment ? 'https://www.google.com/' : url} className='relative'>
                    <WhatsAppIcon className="cursor-pointer hover:opacity-100" />
                </WhatsappShareButton>
            </div>

            <div onClick={() => handleShareClick('TumblrShared')}>
                <TumblrShareButton url={isDevelopment ? 'https://www.google.com/' : url} className='relative'>
                    <TumblrIcon className="cursor-pointer hover:opacity-100" />
                </TumblrShareButton>
            </div>

            <div onClick={() => handleShareClick('EmailShared')}>
                <EmailShareButton url={isDevelopment ? 'https://www.google.com/' : url} className='relative'>
                    <div className="w-8 h-8">
                        <MdEmail className="w-full h-full cursor-pointer hover:opacity-100 text-white bg-purple-400 rounded-full p-2" />
                    </div>
                </EmailShareButton>
            </div>
        </div>
    )
}

export default ReactShare
