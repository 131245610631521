import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={45}
    height={44}
    fill="none"
    {...props}
  >
    <path
      fill="#7CAC8C"
      d="M21.804 14.355c.534-.668.445-2.058.333-2.67-8.544-.534-16.465-1.558-19.358-2.003-1.201-.267-1.502-1-1.502-1.335.267 0 .89-.667 1.169-1l.834 1 1.335-1 .667 1 1.169-1 1 1 1.002-1 .835 1 1.168-1 .834 1 1.168-1 1.002 1L14.96 7.18v-.834L13.46 7.68l-1.002-1.335L11.29 7.68l-.834-1.335L9.288 7.68l-.835-1.335L7.452 7.68 6.45 6.345 5.282 7.68l-.667-1.335L3.28 7.68 2.112 6.345c.133.4-.39 1.057-.668 1.335H.777c-.935-.668-.835-2.058-.668-2.67.267-1.736 2.003-1.39 2.837-1.002 1.335-1.602 2.336-.445 2.67.334h.835c.8-.267 3.782-.667 5.173-.834.4-2.937 2.614-3.56 3.671-3.505 2.136 0 3.227 2.114 3.505 3.171 2.937-.267 5.563.111 6.508.334L26.81 1.17l1.001 2.337 1.502-2.003 1.002 2.503 2.002-1.501V5.01l2.17-.668v2.503l1.835.334-1.001 1.836 1.836 1.669-1.836.667 1.001 1.836h-1.335l.334 1.836h-1.335l.5 1.668h-1.835l.334 1.836-1.67-.668v2.003l-1.334-.5h-1.168c2.002-5.474-1.502-7.176-3.505-7.343-.267 2.002-1.224 3.06-1.669 3.337.935.801.946 2.225.835 2.837l-1.502-.667-2.17 1.835v-2.67l-1.335-1 2.337-1.336Z"
    />
    <path
      fill="#7CAC8C"
      d="m36.322 11.685 1.335-.334c1.602 1.068 1.558 5.23 1.335 7.176 2.403.133 3.227 2.503 3.338 3.671 1.869-.4 2.559.39 2.67.835-.667 0-1.39.779-1.669 1.168.4.668.278 2.17.167 2.837-.267-.4-1.557-.946-2.17-1.168l-1.501 1.168c-.5-.668.326-3.353.334-3.338-.535-1.068-1.447-1.223-1.836-1.168-.134 4.673-7.065 6.731-10.514 7.176-8.01 1.202-10.791 4.506-11.18 6.008-1.336 3.738.11 5.896 1 6.508.535.534.334.779.168.834-3.739-.267-4.562-3.448-4.506-5.006l-1.67-1.669 1.503-.834-1.001-1.669 1.668-1.001-.834-2.003 2.002-.5-.667-2.337h2.17v-2.336l2.002.667.334-2.503 2.002 1.335.334-2.67 1.836 1.836.667-2.336 1.67 1.835.333-2.503 1.836 1.669.667-2.17 1.669.835.167-1.335 2.002 1.001.334-2.003 2.003.501-.501-2.17 1.836.168-.334-1.836h1.669l-1.002-1.669h1.67l-1.336-2.67Z"
    />
    <path
      fill="#fff"
      d="M17.199 3.189c-1.513.316-2.848-.185-4.016.149 0-1.335.835-1.669 1.335-1.836.418-.063 1.002.835 1.168 1.687.167-1.353-.517-1.637-.696-1.91 1.647-.119 2.159 1.224 2.209 1.91Z"
    />
  </svg>
)
export default SvgComponent


