import React from 'react'

const TruncateText = ({ children, index = 80 }: TruncateTextProps) => {

    const truncatedText = children.length > index ? `${children.slice(0, index)} ...` : children
    return (
        <span className="">
            {truncatedText}
        </span>
    )
}

export default TruncateText

interface TruncateTextProps {
    children: string
    index?: number
}