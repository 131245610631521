export const Tags = [
    "Fé",
    "Finanzas",
    "Política",
    "Sociedad",
    "Tecnología",
    "Historia y Tradición",
]

export const tagsId: Record<string, string> = {
    "Fé": "fe",
    "Finanzas": "finanzas",
    "Política": "politica",
    "Sociedad": "sociedad",
    "Tecnología": "tecnologia",
    "Historia y Tradición": "historia",
}

export const tags = (tag: string) => {
    return tagsId[tag]
}