import React from 'react'

const PostCardSkeletonCard = () => {
    return (
        <div className="h-96 border border-1 border-gray-200 rounded-lg animate-pulse">
            <div className="bg-gray-200 rounded-t-lg h-52 w-full" />
            <div className="h-5 w-11/12 bg-gray-200 mt-3 mx-2" />
            <div className="h-5 w-10/12 bg-gray-200 m-2" />
        </div>
    )
}

export default PostCardSkeletonCard
