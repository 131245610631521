import { useMutation, UseMutationOptions } from "react-query";
import { db } from "firebaseConfig/config";
import { doc, runTransaction } from 'firebase/firestore';

interface IVariables {
    articleId: string
    newCount: number
}

const incrementReactions = async ({ articleId, newCount }: IVariables) => {
  const articleRef = doc(db, 'articles', articleId);
  return runTransaction(db, async (transaction) => {
    const articleSnapshot = await transaction.get(articleRef);
    if (!articleSnapshot.exists()) {
      // Document doesn't exist, set initial values
      transaction.set(articleRef, { views: 1, reactions: newCount  });
    } else {
      transaction.update(articleRef, { reactions: newCount });
    }
  });
};

export const useIncrementReactions = (
  options?: UseMutationOptions<void, Error, IVariables>
) => {
  return useMutation(incrementReactions, options);
};

export default useIncrementReactions
