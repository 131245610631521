import React from 'react'
import { AvatarSkeletonCard, RenderIf } from "../";

interface IAvatarProps {
    url?: string
    className?: string
    size?: 'sm' | 'md' | 'lg';
}

const Avatar = ({ url, className = '', size = 'sm' }: IAvatarProps) => {

    const sizes = {
        'sm': 'w-12 h-12',
        'md': 'w-36 h-36',
        'lg': 'w-44 h-44',
    }

    return (
        <>
            <RenderIf isTrue={!url}>
                <AvatarSkeletonCard />
            </RenderIf>
            <RenderIf isTrue={url}>
                <img
                    src={url}
                    alt="Author avatar showing his picture"
                    className={`rounded-full ${sizes[size]} ${className} opacity-80`}
                />
            </RenderIf>
        </>
    )
}

export default Avatar
