import React from 'react'
import { iconProps } from "./types"

const TumblrIcon = (props: iconProps) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" className={`bg-gray-900 rounded-full opacity-80 ${props.className}`}>
            <path d="M14.5 7C14.5 9.82979 11 12 10 12V15H13V21C13 23.2091 14.7909 25 17 25H21V20.9574C20.605 20.9574 19.05 20.9574 17.9992 20.9574C17.447 20.9574 17 20.5097 17 19.9574V15H21V11H17V7H14.5Z" fill="white" />
        </svg>
    )
}

export default TumblrIcon

