import React from 'react'
import {
  Header, TopMenu, ResponsiveWrap, FloatingButton, Footer, Divider, RenderIf,
  Avatar
} from '../../components'
import { GiJerusalemCross } from "react-icons/gi";
import { useQuery } from "@apollo/client";
import { GET_AUTHOR } from "../../apollo/queries/authors";
// import { IAuthor } from 'types'
import { useParams } from 'react-router-dom';
import { richTextRenderer } from "../../helpers/richTextRenderer";


const AuthorBio = () => {
  const { id } = useParams<{id: string}>()


  const { loading, data } = useQuery(GET_AUTHOR, {
      skip: !id,
      variables: { id },
      onError: (error) => console.log('Error fetching author bio', error),
      onCompleted: res => console.log({ res })
  })

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])

  return (
      <div className='flex flex-col min-h-screen'>
        <Header />
        <div className="sticky top-0 z-40">
            <TopMenu />
        </div>
        <div className="flex -mt-3">
            <Divider className="-mt-2" />
            <GiJerusalemCross className='text-5xl mx-2 font-bold' />
            <Divider className="-mt-2" />
        </div>
        <ResponsiveWrap className="flex-grow">        
          <RenderIf isTrue={loading}>
            Loading ...
          </RenderIf>
          
          <RenderIf isTrue={data}>
          <div className='w-full mb-24'>            
              <div className='flex flex-col justify-between items-center'>
                <Avatar url={data?.author?.avatar?.url} size='md' className='mt-6' />
                <h6 className='mt-4 cursor-pointer hover:text-pink-600'>
                    {data?.author?.name}
                </h6>
            </div>
            
            <div className='my-6 text-justify lg:text-left w-full lg:w-6/12 lg:mx-auto'>
              {richTextRenderer(data?.author?.bio?.json)}
            </div>
            </div>
          </RenderIf>
              
        </ResponsiveWrap>
        <Footer />
        <FloatingButton scrollY={500} />
      </div>
  )
}

export default AuthorBio
