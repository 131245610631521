import React from 'react'

const PostCardSmSkeleton = () => {
    return (
        <div className="h-44 border border-r-4 border-gray-200 flex mb-3 animate-pulse">
            <div className="flex-1 bg-gray-200"></div>
            <div className="flex-1 p-4 flex flex-col justify-between">
                <div className="flex flex-col">
                    <div className="w-full h-4 bg-gray-200 mb-3" />
                    <div className="w-full h-4 bg-gray-200 mb-3" />
                    <div className="w-3/4 h-4 bg-gray-200 mb-3" />
                </div>
                <div className="w-24 h-4 bg-gray-200 mb-3" />
            </div>
        </div>
    )
}

export default PostCardSmSkeleton
