import { useMutation, UseMutationOptions } from "react-query";
import { db } from "firebaseConfig/config";
import { doc, runTransaction } from "firebase/firestore";
import { Comment } from "types/firebaseTypes";

interface AddTopLevelCommentVariables {
  postId: string;
  username: string;
  text: string;
  avatar: string; // Include avatar
}

const addTopLevelComment = async ({ postId, username, text, avatar }: AddTopLevelCommentVariables): Promise<void> => {
  const newComment: Comment = {
    id: `comment-${Date.now()}`,
    username,
    text,
    avatar, // Include avatar
    replies: []
  };

  const articleRef = doc(db, 'articles', postId);
  await runTransaction(db, async (transaction) => {
    const articleSnapshot = await transaction.get(articleRef);
    if (!articleSnapshot.exists()) {
      transaction.set(articleRef, { views: 1, reactions: 0, comments: [newComment] });
    } else {
      const comments = articleSnapshot.data().comments || [];
      transaction.update(articleRef, { comments: [...comments, newComment] });
    }
  });
};

export const useAddTopLevelComment = (
  options?: UseMutationOptions<void, Error, AddTopLevelCommentVariables>
) => {
  return useMutation(addTopLevelComment, options);
};

export default useAddTopLevelComment;
