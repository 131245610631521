import React from 'react'
import { Header, TopMenu, ResponsiveWrap, FloatingButton, Footer, Divider, RenderIf, Avatar } from '../../components'
import { GiJerusalemCross } from "react-icons/gi";
import { useQuery } from "@apollo/client";
import { GET_AUTHOR_COLLECTION } from "../../apollo/queries/authors";
import { IAuthor } from 'types'
import { FaSquareXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const Who = () => {

  // Fetching authors
  const { loading, data } = useQuery(GET_AUTHOR_COLLECTION, {
    onCompleted: (res) => { 
      console.log({ res })
    },
    onError: error => console.log({ error })
  })


  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])

  return (
      <div className='flex flex-col min-h-screen'>
        <Header />
        <div className="sticky top-0 z-40">
            <TopMenu />
        </div>
        <div className="flex -mt-3">
            <Divider className="-mt-2" />
            <GiJerusalemCross className='text-5xl mx-2 font-bold' />
            <Divider className="-mt-2" />
        </div>
      <ResponsiveWrap className="flex-grow">
        
        <div className='w-full text-center'>
          <h1 className='font-bold text-2xl text-pink-600 mt-8'>Nuestro Talento</h1>
          </div>
          <RenderIf isTrue={loading}>
            Loading ...          
          </RenderIf>
        
        <RenderIf isTrue={data}>
          <div className='w-full flex flex-wrap items-start justify-center mb-44'>
            {data?.authorCollection?.items?.map((item: IAuthor) => (
              <div
                key={item.sys.id}
                className='flex flex-col justify-between items-center shadow p-6 mr-4 mt-12'
              >
                <Avatar url={item?.avatar?.url} size='md' />
  
                <h6 className='mt-4 cursor-pointer hover:text-pink-600'>
                  <Link to={`author/${item?.sys?.id}`}>
                    {item?.name}
                  </Link>
                </h6>

                <div className='mt-4 flex flex-wrap'>
                  <span className='px-2 rounded text-xs bg-pink-600 text-white'>Author</span>
                  <RenderIf isTrue={item?.name === 'Rolando Niubó'}>
                    <span className='px-2 rounded text-xs bg-indigo-500 text-white ml-2'>Software</span>
                  </RenderIf>
                  <RenderIf isTrue={item?.name === 'Ignatius of Loyola'}>
                    <span className='px-2 rounded text-xs bg-green-500 text-white ml-2'>Editor</span>
                  </RenderIf>
                </div>

                <h6 className="cursor-pointer mt-2">
                  <a href={item?.xProfileUrl} target="_blank" rel="noopener noreferrer">
                    <FaSquareXTwitter className='text-2xl' />
                  </a>
                </h6>
              </div>
            ))}
          </div>
          </RenderIf>
            
        </ResponsiveWrap>
        <Footer />
        <FloatingButton scrollY={500} />
      </div>
  )
}

export default Who
