import React from 'react'

interface IProps {
    widthClass?: string,
    smWidthClass?: string,
    mdWidthClass?: string,
    lgWidthClass?: string,
    xlWidthClass?: string,
    className?: string,
}

const Divider = ({
    widthClass = 'w-full',
    smWidthClass = '',
    mdWidthClass = '',
    lgWidthClass = '',
    xlWidthClass = '',
    className = '',
}: IProps) => {
  return (
    <div className={`w-full flex justify-center items-center mx-auto ${className}`}>
      <div className={`${widthClass} ${smWidthClass} ${mdWidthClass} ${lgWidthClass} ${xlWidthClass} border border-t-gray-500 mt-2`} />
    </div>
  )
}

export default Divider