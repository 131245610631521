import { useMutation, UseMutationOptions } from "react-query";
import { db } from "firebaseConfig/config";
import { doc, runTransaction } from "firebase/firestore";
import { Comment } from "types/firebaseTypes";

interface AddReplyCommentVariables {
  postId: string;
  parentId: string;
  username: string;
  text: string;
  avatar: string; // Include avatar
}

const addReplyComment = async ({ postId, parentId, username, text, avatar }: AddReplyCommentVariables): Promise<void> => {
  const newReply: Comment = {
    id: `reply-${Date.now()}`,
    username,
    text,
    avatar, // Include avatar
    replies: []
  };

  const articleRef = doc(db, 'articles', postId);
  await runTransaction(db, async (transaction) => {
    const articleSnapshot = await transaction.get(articleRef);
    if (articleSnapshot.exists()) {
      const comments = articleSnapshot.data().comments || [];
      const updateComments = (comments: Comment[]): Comment[] => {
        return comments.map(comment => {
          if (comment.id === parentId) {
            return {
              ...comment,
              replies: [...comment.replies, newReply]
            };
          } else if (comment?.replies?.length > 0) {
            return {
              ...comment,
              replies: updateComments(comment.replies)
            };
          }
          return comment;
        });
      };
      transaction.update(articleRef, { comments: updateComments(comments) });
    }
  });
};

export const useAddReplyComment = (
  options?: UseMutationOptions<void, Error, AddReplyCommentVariables>
) => {
  return useMutation(addReplyComment, options);
};

export default useAddReplyComment;
