import React from 'react'
import { HomePage, PostPage, FilteredPage, MissionStatement, Who, AuthorBio } from "./pages";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { BurgerMenu } from "./components";

const App = () => {

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/'>
          <HomePage />
        </Route>
        <Route path='/post/:id' exact>
          <PostPage />
        </Route>
        <Route path='/mission' exact>
          <MissionStatement />
        </Route>
        <Route path='/who' exact>
          <Who />
        </Route>
        <Route path='/:tag' exact>
          <FilteredPage />
        </Route>
        <Route path='/author/:id' exact>
          <AuthorBio />
        </Route>
      </Switch>
      <div className='md:hidden'>
        <BurgerMenu />
      </div>
    </BrowserRouter>
  );
};

export default App;

// Mask apis and keys with env files
// Icons to Like
// Article views counter
// SEO optimization

