/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { RenderIf, ImageSkeletonCard } from "../";
import { formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";
// import { translator } from "../../helpers/formatDistanceToNowTranslator";
import { Link } from "react-router-dom";
import { IBlogPost } from 'types'
import { Avatar } from 'components'
import { useArticleData } from 'hooks';
import { GiSunglasses } from "react-icons/gi";
import { PiHandsClappingThin } from "react-icons/pi";
import { GoShareAndroid } from "react-icons/go";


interface CarouselProps {
    data: IBlogPost[]
}

const Carousel = ({ data }: CarouselProps) => {
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0)
    const [hoveringFlag, setHoveringFlag] = React.useState(false)
    const selectedUrl = data?.[selectedIndex]?.blogPicture?.url

    // get post views, shares and reactions from firebase
    const { data: articleViewsAndShares } = useArticleData(data?.[3]?.sys?.id, {
        enabled: Boolean(data?.[3]?.sys?.id),
        onSuccess: res => {
            console.log({ res })
        }
    })

    const transitionImage = () => {
        if (hoveringFlag) return;
        if (selectedIndex === 2) {
            setSelectedIndex(0)
        } else if (selectedIndex !== 2) {
            setSelectedIndex(selectedIndex => selectedIndex + 1)
        }
    }

    React.useEffect(() => {
        const interval = setInterval(transitionImage, 5000)
        return () => clearInterval(interval)
    }, [selectedIndex, hoveringFlag])

    const onMouseLeaveHandler = () => setHoveringFlag(false)
    const onMouseEnterHandler = (index: number) => {
        setHoveringFlag(true)
        setSelectedIndex(index)
    }


    const totalShares = Object.entries(articleViewsAndShares || {})
        .filter(([key]) => !["reactions", "views", "comments"].includes(key))
        .reduce((acc, [, value]) => acc + value, 0);

    return (
        <div className="w-full mb-12 flex flex-wrap items-stretch">

            {/* Image */}
            <span className="w-full lg:w-5/12 relative">
                <RenderIf isTrue={!selectedUrl}>
                    <ImageSkeletonCard />
                </RenderIf>

                <RenderIf isTrue={selectedUrl}>
                    {data?.slice(0, 3).map(((post, index) => (
                        <React.Fragment key={post?.sys?.id}>
                            <RenderIf isTrue={index === selectedIndex}>
                                <div className={`transition duration-700 ease-in-out ${index === selectedIndex ? 'opacity-100' : 'opacity-0'}`}>
                                    <Link key={post?.title + post?.sys?.id} to={`/post/${post?.sys?.id}`}>
                                        <img
                                            src={post.blogPicture?.url}
                                            alt="Selected picture for carousel component"
                                            className="fade-in"
                                        />
                                        <div className="absolute top-0 left-0 w-full p-3 bg-gray-900 opacity-90 flex items-center justify-end opacity-80">
                                                <h1 className="font-bold text-white md:text-xl lg:text-3xl abril-fatface-regular hover:text-gray-800">
                                                    {post.title}
                                                </h1>
                                        </div>
                                    </Link>
                                    <div className="w-full h-12 bg-gray-900 flex items-center justify-end px-6">
                                        <h1 className="font-medium text-white md:text-xs lg:text-md xl:text-lg">
                                            {formatDistanceToNow(new Date(post?.publishedDate), { addSuffix: true, locale: es })} ago.
                                        </h1>
                                    </div>
                                </div>
                                <RenderIf isTrue={index === selectedIndex}>
                                    <div style={{ height: '110px' }}>
                                        <div className='text-gray-600 text-sm hover:text-gray-500 transition ease-in-out mt-2 italic' >
                                            {post?.summary}
                                        </div>
                                        <h2 className='text-gray-600 italic text-xs mt-2 transition ease-in-out'>
                                            {post?.author?.toUpperCase()}
                                        </h2>
                                    </div>
                                </RenderIf>
                            </RenderIf>
                        </React.Fragment>
                    )))}
                </RenderIf>
            </span>

            {/* Center side links */}
            <div className="w-full lg:w-3/12 flex-grow flex flex-col border-l border-r border-gray-300 lg:px-2 lg:mx-4 mt-6 lg:mt-0">
                {data?.slice(0, 3).map(({ title, sys, summary, author }: IBlogPost, index: number) =>
                    <Link key={title + sys?.id} to={`/post/${sys?.id}`}>
                        <div
                            className={`flex flex-col ${index === data?.length - 2 ? `` : `border-b border-gray-400`} py-4`}
                            onMouseEnter={() => onMouseEnterHandler(index)}
                            onMouseLeave={onMouseLeaveHandler}
                        >
                            <h1 className={`text-gray-900 mx-3 cursor-pointer font-bold hover:text-gray-500 ${index ? `pt-3` : ``} abril-fatface-regular text-xl`}>
                                {title}
                            </h1>
                            <h2 className='text-gray-600 ml-3 mt-2 mb-1 text-xs'>
                                {summary}
                            </h2>                            
                            <h2 className='text-gray-600 ml-3 mt-2 mb-1 text-xs italic'>
                                {author?.toUpperCase()}
                            </h2>                            
                        </div>
                    </Link>
                )}
            </div>

            {/* Articulo Destacado o Recomendado */}
            <div className='w-full lg:w-1/4'>
                <Link key={data?.[3]?.title + data?.[3]?.sys?.id} to={`/post/${data?.[3]?.sys?.id}`}>
                    <div className="w-full flex-grow flex flex-col bg-gray-50 hoveranimation mt-12 lg:mt-0">
                        <div className='relative'>
                            <img
                                src={data?.[3]?.blogPicture?.url}
                                alt="Imagen de Articulo Recomendado"
                                className="fade-in opacity-70"
                            />
                            <div className='text-sm absolute top-40 right-2'>
                                <span className='bg-pink-600 px-4 py-1 rounded font-semibold text-white'>
                                    Recomendado
                                </span>
                            </div>
                        </div>
                        
                        <h1 className={`w-11/12 text-left text-gray-900 mx-4 my-4 cursor-pointer font-bold hover:text-gray-500 abril-fatface-regular text-2xl`}>
                            {data?.[3]?.title}
                        </h1>

                        <div className='flex flex-col items-center justify-center mt-2'>
                            <Avatar url={data?.[3]?.avatar?.url} />
                            <h2 className='text-gray-600 italic text-xs mt-2'>
                                {data?.[3]?.author?.toUpperCase()}
                            </h2>
                        </div>

                        <div className='w-full flex flex-wrap items-center justify-center mt-2 mb-8'>
                            <h2 className="text-gray-500 flex items-center">
                                <PiHandsClappingThin
                                    className={`w-6 h-6 cursor-pointer active:translate-y-0.5 text-gray-500`}
                                />
                                {articleViewsAndShares?.reactions}
                            </h2>
                            <h2 className="mx-6 text-gray-500 flex items-center">
                                <GiSunglasses className='mr-1 w-6 h-6' />
                                {articleViewsAndShares?.views}
                            </h2>
                            <h2 className="text-gray-500 flex items-center">
                                <GoShareAndroid className='mr-1 w-6 h-6' />
                                {totalShares}
                            </h2>
                        </div>

                    </div>
                </Link>
            </div>
        </div >
    )
}

export default Carousel
