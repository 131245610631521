import { gql } from "@apollo/client";


export const GET_POST_COLLECTION = gql`
    query PostCollection {
        blogPostCollection(limit: 8) {
            items {
                sys {
                    id
                }                
                blogPicture {
                    url
                }
                title
                publishedDate
                author
                summary
                avatar {
                  url
                }
            }
        }
    }`

export const GET_LAST_THREE_POST = gql`
    query PostCollection {
        blogPostCollection(limit: 3) {
            items {
                sys {
                    id
                }                
                blogPicture {
                    url
                }
                title
                publishedDate
                author
                summary
            }
        }
    }`


export const GET_FILTERED_COLLECTION_FOR_DISPLAY = gql`
query GET_COLELCTION($tag: String){
  blogPostCollection(
      limit: 4,
        where: {
            contentfulMetadata: {
                tags:{
                    id_contains_some: [$tag]
                }
            }
        }
  ) {
    items {
    sys {
        id
    }
    blogPicture {
        url
    }
        title
        publishedDate
        author
    }
  }
}`


export const GET_FILTERED_COLLECTION_BY_TAG = gql`
query GET_COLELCTION($tag: String){
  blogPostCollection(
        where: {
            contentfulMetadata: {
                tags:{
                    id_contains_some: [$tag]
                }
            }
        }
  ) {
    items {
    sys {
        id
    }
    blogPicture {
        url
    }
    title
    publishedDate
    author
    summary
    }
  }
}`


export const GET_POST = gql`
    query getPost($id: String!) {
    blogPost (id: $id) {
      title
      publishedDate
      blogPicture {
        url
      }
      postBody {
        json
        links {
          entries {
            inline {
              sys {
                id
              }
            }
            block {
              sys {
                id
              }
            }
          }
          assets {
            block {
              sys {
                id
              }
              url
            }
          }
        }
      }
      author
      views
      summary
      avatar {
        url
      }
      postMetaData
    }
  }`

