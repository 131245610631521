import { useQuery, QueryKey, UseQueryOptions } from "react-query";
import { db } from "firebaseConfig/config";
import { doc, getDoc } from 'firebase/firestore';
import { IArticleShares } from "types/firebaseTypes";

interface IQueryKey {
    queryKey: QueryKey;
}

const getArticles = async ({ queryKey }: IQueryKey) => {
    const articleRef = doc(db, 'articles', queryKey[1] as string);
    const articleSnapshot = await getDoc(articleRef);

    if (articleSnapshot) {
        console.log({ articleSnapshot })
        return articleSnapshot.data() as IArticleShares;
    }
    throw new Error("Article not found");
  }

export const useArticleData = (
    articleId: string,
    options?: UseQueryOptions<IArticleShares, Error, IArticleShares, string[]>,
) => {
    return useQuery(
        ['articleData', articleId],
        getArticles,
        options,
    );
};

export default useArticleData