import React from 'react'
import { formatDistanceToNow } from "date-fns";
// import { translator } from "../../helpers/formatDistanceToNowTranslator";
import { TruncateText } from "../";
import { PostCardSkeletonCard, RenderIf } from "../";
import { IBlogPost } from "types";


const PostCard = ({
    blogPicture,
    publishedDate,
    title,
    author,
}: IBlogPost) => {

    return (
        <>
            <RenderIf isTrue={blogPicture && title}>
                <div className="lg:h-80 cursor-pointer bg-white hoveranimation relative" style={{ height: '350px' }}>
                    <div>
                        <div className="relative">
                            <img className="pointer-events-none" src={blogPicture.url} alt={title} />
                        </div>

                    </div>
                    <h1 className="text-xl text-gray-700 mt-3 mb-2 mx-3 font-semibold cursor-pointer hover:text-gray-500 leading-tight abril-fatface-regular">
                        <TruncateText>
                            {title}
                        </TruncateText>
                    </h1>
                    <h6 className="text-xs text-gray-500 px-3 py-2 absolute bottom-1 left-1">
                        By {author}
                    </h6>
                    <h6 className="text-xs text-gray-500 px-3 py-2 absolute bottom-1 right-1">
                        {/* {translator(formatDistanceToNow(new Date(publishedDate)))}. */}
                        {formatDistanceToNow(new Date(publishedDate))} ago.
                    </h6>
                </div>
            </RenderIf>
            <RenderIf isTrue={!blogPicture.url || !title}>
                <PostCardSkeletonCard />
            </RenderIf>
        </>

    )
}

export default PostCard

