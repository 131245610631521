import * as React from "react"
import { iconProps } from "./types"

function WhatsAppIcon(props: iconProps) {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props} className={`bg-gradient-to-tr from-green-600 to-green-500 rounded-full opacity-80 ${props.className}`}>
            <path d="M12.5 9.50001C12.1672 8.83143 11.6565 8.89062 11.1407 8.89062C10.2188 8.89062 8.78125 9.9949 8.78125 12.0501C8.78125 13.7344 9.52345 15.5782 12.0244 18.3362C14.438 20.998 17.6094 22.3749 20.2422 22.3281C22.875 22.2812 23.4167 20.0156 23.4167 19.2504C23.4167 18.9113 23.2062 18.7421 23.0613 18.6961C22.1641 18.2656 20.5093 17.4633 20.1328 17.3125C19.7563 17.1618 19.5597 17.3657 19.4375 17.4766C19.0961 17.802 18.4193 18.7609 18.1875 18.9766C17.9558 19.1923 17.6103 19.0831 17.4665 19.0016C16.9374 18.7893 15.5029 18.1512 14.3595 17.0427C12.9453 15.6719 12.8623 15.2003 12.5959 14.7804C12.3828 14.4446 12.5392 14.2385 12.6172 14.1484C12.9219 13.7969 13.3426 13.2541 13.5313 12.9844C13.7199 12.7147 13.5702 12.3051 13.4803 12.0501C13.0938 10.9531 12.7663 10.0349 12.5 9.50001Z" fill="white" />
        </svg>
    )
}

export default WhatsAppIcon
