/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useParams, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
    Header,
    TopMenu,
    ResponsiveWrap,
    PostCardSm,
    PostCardSmSkeleton,
    RenderIf,
    FloatingButton,
    Footer,
    Divider,
} from "../../components";
import { GET_FILTERED_COLLECTION_BY_TAG } from "../../apollo/queries/posts";
import { GiJerusalemCross } from "react-icons/gi";
import { IBlogPost } from 'types'

const FilteredPage = () => {

    const { tag }: { tag: string } = useParams()

    const { loading, data } = useQuery(GET_FILTERED_COLLECTION_BY_TAG, {
        variables: { tag },
        onError: (error) => console.log('Error fetching filtered posts', error)
    })

    
    const firstArticle = data?.blogPostCollection?.items?.slice(0, 1)[0]
    const secondToFourthArticles = data?.blogPostCollection?.items?.slice(1, 4)
    const allTheRest =data?.blogPostCollection?.items?.slice(4)

    return (
        <div className="w-full flex flex-col min-h-screen">
            <Header />
            <div className="sticky top-0 z-40">
                <TopMenu />
            </div>
            <div className="flex -mt-3">
                <Divider className="-mt-2" />
                <GiJerusalemCross className='text-5xl mx-2 font-bold' />
                <Divider className="-mt-2" />
            </div>
            <ResponsiveWrap>
                <div className="mb-24 w-full">
                    <RenderIf isTrue={loading}>
                        {[...new Array(3).fill("")].map((item: string, index: number) => (
                            <PostCardSmSkeleton key={index} />
                        ))}
                    </RenderIf>
                    <RenderIf isTrue={!loading && data}>
                        <div className='w-full flex flex-col lg:flex-row mt-12'>

                            {/* First article */}
                            <div className='flex flex-col items-start mr-2 w-full lg:w-3/5'>
                                <img
                                    src={firstArticle?.blogPicture?.url}
                                    alt="Selected picture for carousel component"
                                />
                                <Link key={firstArticle?.title + firstArticle?.sys?.id} to={`/post/${firstArticle?.sys?.id}`}>
                                    <h1 className="font-bold text-gray-800 md:text-xl lg:text-3xl abril-fatface-regular">
                                        {firstArticle?.title}
                                    </h1>
                                    <p className='text-gray-600 text-xs italic my-2'>
                                        {firstArticle?.summary}
                                    </p>
                                    <h2 className='text-gray-600 italic text-xs mt-2 transition ease-in-out'>
                                        {firstArticle?.author?.toUpperCase()}
                                    </h2>
                                </Link>
                            </div>

                            {/* Second article */}
                            <div className="w-full mt-12 lg:w-2/5 lg:mt-0">
                                {secondToFourthArticles?.map((post: IBlogPost) => (
                                    <Link
                                        to={`./post/${post?.sys?.id}`}
                                        key={post?.title + post?.sys?.id}
                                    >
                                        <PostCardSm
                                            image={post?.blogPicture?.url}
                                            publishedDate={post.publishedDate}
                                            title={post.title}
                                            author={post.author}
                                        />
                                    </Link>
                                ))}
                            </div>
                        </div>

                        <Divider className='mt-6 opacity-50' />
                        {/*  List  */}
                        <div className="flex flex-col mb-16 w-full mt-6">
                            {allTheRest?.map((post: IBlogPost) => (
                                <Link
                                    to={`./post/${post?.sys?.id}`}
                                    key={post?.title + post?.sys?.id}
                                    className='mb-4 md:mb-0'
                                >
                                    <div className='flex align-middle'>
                                        <GiJerusalemCross className='mr-1 w-4 h-4 flex-shrink-0' />
                                        <span className='underline underline-offset-1 -mt-1 mb-2 hover:text-pink-600'>
                                            {post.title}
                                        </span>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </RenderIf>
                </div>
            </ResponsiveWrap>
            <Footer />
            <FloatingButton scrollY={500} />
        </div>
    )
}

export default FilteredPage
