import React from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from "@apollo/client";
import { GET_POST_COLLECTION, GET_FILTERED_COLLECTION_FOR_DISPLAY } from "../../apollo/queries/posts";
import { TopMenu, ResponsiveWrap, Carousel, FloatingButton, Divider, Footer, Header} from 'components'
import { tags } from "../../helpers/tags";
import HorizontalMap from './components/HorizontalMap';
import ColumnMap from './components/ColumnMap';
import ListMap from './components/ListMap';
import { GiJerusalemCross } from "react-icons/gi";


// import Helmet from '../../assets/helmet.png';

function HomePage() {
  const { pathname } = useLocation()

  //Fetch for Carousel and HorizontalMap
  const { data } = useQuery(GET_POST_COLLECTION)

  // Fetch(s) for filtered Column Maps
  // Imperatively put the Tag you want to fetch
  const { data: feData } = useQuery(GET_FILTERED_COLLECTION_FOR_DISPLAY, {
    variables: { tag: tags(`Fé`) },
  })
  
  const { data: finanzasData } = useQuery(GET_FILTERED_COLLECTION_FOR_DISPLAY, {
    variables: { tag: tags('Finanzas') },
  })

  const { data: politicaData } = useQuery(GET_FILTERED_COLLECTION_FOR_DISPLAY, {
    variables: { tag: tags(`Política`) },
  })

  const { data: sociedadData } = useQuery(GET_FILTERED_COLLECTION_FOR_DISPLAY, {
    variables: { tag: tags('Sociedad') },
  })

  const { data: techData } = useQuery(GET_FILTERED_COLLECTION_FOR_DISPLAY, {
    variables: { tag: tags('Tecnología') },
  })

  const { data: historiaData } = useQuery(GET_FILTERED_COLLECTION_FOR_DISPLAY, {
    variables: { tag: tags('Historia y Tradición') },
  })

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <React.Fragment>
      <Header />
      <div className="sticky top-0 z-40">
          <TopMenu />
      </div>
      <div className="flex -mt-3">
        <Divider className="-mt-2" />
        <GiJerusalemCross className='text-5xl mx-2 font-bold' />
        <Divider className="-mt-2" />
      </div>

      <ResponsiveWrap>
        {/*  Carousel */}
        <div className="mt-12">
          <Carousel data={data?.blogPostCollection?.items?.slice(0, 4)} />
        </div>
        {/*  Horizontal row of PostCards  */}
        <div className='mt-0'>
          <HorizontalMap data={data?.blogPostCollection?.items} />
        </div>
        {/*  Columns  */}
        <div className="flex flex-col lg:flex-row lg:space-x-12 my-8">
          <div className="lg:flex-1">
            <ColumnMap data={feData?.blogPostCollection?.items} heading="Fé" />
          </div>
          <div className="lg:flex-1">
            <ColumnMap data={finanzasData?.blogPostCollection?.items} heading="Finanzas" />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row lg:space-x-12 my-8">
          <ListMap data={politicaData?.blogPostCollection?.items} heading="Política" />
          <ListMap data={sociedadData?.blogPostCollection?.items} heading="Sociedad" />
        </div>

        <div className="flex flex-col lg:flex-row lg:space-x-12 my-8">
          <ListMap data={techData?.blogPostCollection?.items} heading="Tecnología" />
          <ListMap data={historiaData?.blogPostCollection?.items} heading="Historia" />
        </div>

      </ResponsiveWrap>
      <Footer />

      <FloatingButton scrollY={500} />
    </React.Fragment>
  )
}

export default HomePage
