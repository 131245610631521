import React from 'react'

const RenderIf = ({ isTrue, children }: RenderIfProps) => {
    if (Boolean(isTrue)) {
        return <React.Fragment>{children}</React.Fragment>
    }
    return null
}

export default RenderIf

interface RenderIfProps {
    children?: React.ReactNode
    isTrue?: any
}