import React from 'react'
import { Link } from "react-router-dom";
import { PostCardSm, Title } from "../../../components";

const ColumnMap = ({ data, heading }: ColumnProps) => {
    return (
        <div>
            <Title className="mb-8">{heading}</Title>
            <div className="flex flex-col mb-16 w-full">
                {data?.map((post) => (
                    <Link
                        to={`./post/${post?.sys?.id}`}
                        key={post?.title + post?.sys?.id}
                    >
                        <PostCardSm
                            image={post?.blogPicture?.url}
                            publishedDate={post.publishedDate}
                            title={post.title}
                            author={post.author}
                        />
                    </Link>
                ))}
            </div>
        </div>
    )
}

export default ColumnMap

interface ColumnProps {
    data: any[]
    heading: string
}
