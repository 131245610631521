import React from 'react'

const Title = ({ children, className }: TitleProps) => {
    return (
        <div className={`flex items-center ${className}`}>
            <span className="w-4 h-4 border-t-8 border-l-8 border-pink-600 mr-3" />

            <h1 className="text-lg text-gray-700 font-black ml-1">
                {children}
            </h1>
        </div>
    )
}

export default Title

interface TitleProps {
    children: string
    className?: string
}