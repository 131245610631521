import React, { useState, useEffect } from 'react';
import { useAddTopLevelComment, useAddReplyComment } from 'hooks';
import { Comment as CommentType } from 'types/firebaseTypes';
import { useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

interface CommentsProps {
  postId: string;
  comments: CommentType[];
}

const generateAvatarUrl = (id: string) => `https://api.dicebear.com/8.x/bottts/svg?seed=$${id}&mouth=smile01`;

const Comments: React.FC<CommentsProps> = ({ postId, comments }) => {
  const queryClient = useQueryClient();
  const [newComment, setNewComment] = useState('');
  const [username, setUsername] = useState(localStorage.getItem('contra-revolucion-username') || '');
  const [avatar, setAvatar] = useState(localStorage.getItem('contra-revolucion-avatar') || '');

  const { mutate: addTopLevelComment } = useAddTopLevelComment({
    onSuccess: () => {
      queryClient.invalidateQueries('articleData');
    },
  });

  const { mutate: addReplyComment } = useAddReplyComment({
    onSuccess: () => {
      queryClient.invalidateQueries('articleData');
    },
  });

  useEffect(() => {
    const storedUsername = localStorage.getItem('contra-revolucion-username');
    const storedAvatar = localStorage.getItem('contra-revolucion-avatar');
    if (storedUsername && storedAvatar) {
      setUsername(storedUsername);
      setAvatar(storedAvatar);
    }
  }, []);

  const handleAddTopLevelComment = () => {
    if (newComment.trim() && username.trim()) {
      if (!avatar) {
        const newId = uuidv4();
        const newAvatar = generateAvatarUrl(newId);
        setAvatar(newAvatar);
        localStorage.setItem('contra-revolucion-avatar', newAvatar);
      }
      addTopLevelComment({ postId, username, text: newComment, avatar });
      localStorage.setItem('contra-revolucion-username', username);
      setNewComment('');
    }
  };

  const handleAddReplyComment = (parentId: string, replyText: string, replyUsername: string) => {
    if (replyText.trim() && replyUsername.trim()) {
      addReplyComment({ postId, parentId, username: replyUsername, text: replyText, avatar });
      localStorage.setItem('contra-revolucion-username', replyUsername);
    }
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  return (
    <div className='mt-24'>
      <div className='w-full text-center mb-12'>
        <h3 className='text-gray-500 text-xl'>Sección de Comentarios</h3>
        <h3 className='mb-2 text-gray-500 text-sm'>Bienvenido! Sean despiadadamente honestos en sus comentarios! Gracias.</h3>
      </div>
      <div>
        {comments?.map(comment => (
          <Comment
            key={comment.id}
            comment={comment}
            postId={postId}
            handleAddReplyComment={handleAddReplyComment}
            depth={1}
            avatar={avatar}
          />
        ))}
      </div>
      <div className='mt-6 flex flex-col items-start'>
        {localStorage.getItem('contra-revolucion-username') ? (
          <div className='mb-1 p-1'>
            <b>{localStorage.getItem('contra-revolucion-username')}</b>
          </div>
        ) : (
          <input
            type="text"
            value={username}
            onChange={handleUsernameChange}
            placeholder="Usuario"
            className='mb-1 p-1 border border-gray-300 rounded'
          />
        )}
        <textarea
          rows={6}
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Agregar comentario aqui"
          className='p-1 border rounded w-full'
        />
      </div>
      <button
        onClick={handleAddTopLevelComment}
        className='mt-4 py-2 px-4 bg-blue-600 text-white rounded'
      >
        Publicar
      </button>
    </div>
  );
};

interface CommentProps {
  comment: CommentType;
  postId: string;
  handleAddReplyComment: (parentId: string, replyText: string, replyUsername: string) => void;
  depth: number;
  avatar: string;
}

const Comment: React.FC<CommentProps> = ({ comment, postId, handleAddReplyComment, depth, avatar }) => {
  const [newReply, setNewReply] = useState('');
  const [replyUsername, setReplyUsername] = useState(localStorage.getItem('contra-revolucion-username') || '');
  const [showReplyInput, setShowReplyInput] = useState(false);

  const handleReplySubmit = () => {
    handleAddReplyComment(comment.id, newReply, replyUsername);
    setNewReply('');
    setShowReplyInput(false);
  };

  const handleReplyUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReplyUsername(e.target.value);
  };

  useEffect(() => {
    const storedUsername = localStorage.getItem('contra-revolucion-username');
    if (storedUsername) {
      setReplyUsername(storedUsername);
    }
  }, []);

  // Function to render text with paragraphs
  const renderTextWithParagraphs = (text: string) => {
    return text.split('\n')?.map((line, index) => (
      <p key={index} className="mb-2">
        {line}
      </p>
    ));
  };

  return (
    <div className={depth > 1 ? 'ml-6 border-l' : ''}>
      <div className={depth > 1 ? 'ml-6' : ''}>
        <div className='pt-2'>
          <div className='py-3 px-6 bg-gray-100 rounded-2xl'>
            <div className='flex items-center mb-0.5'>
              <img src={comment.avatar || avatar} alt="avatar" className="w-10 h-10 rounded-full mr-2 bg-white" />
              <div className='font-medium'>{comment.username}</div>
            </div>
            <div>{renderTextWithParagraphs(comment.text)}</div>
          </div>
        </div>
        {comment.replies?.map(reply => (
          <Comment
            key={reply.id}
            comment={reply}
            postId={postId}
            handleAddReplyComment={handleAddReplyComment}
            depth={depth + 1}
            avatar={avatar}
          />
        ))}

        {depth < 2 && !showReplyInput && (
          <button onClick={() => setShowReplyInput(true)} className='text-xs ml-6'>
            Agregar a este hilo
          </button>
        )}

        {depth < 2 && showReplyInput && (
          <div className='ml-6 my-6 flex flex-col items-start'>
            {localStorage.getItem('contra-revolucion-username') ? (
              <div className='mb-1 p-1'>
                <b>{localStorage.getItem('contra-revolucion-username')}</b>
              </div>
            ) : (
              <input
                type="text"
                value={replyUsername}
                onChange={handleReplyUsernameChange}
                placeholder="Usuario"
                className='mb-1 p-1 border border-gray-300 rounded'
              />
            )}
            <textarea
              rows={6}
              value={newReply}
              onChange={(e) => setNewReply(e.target.value)}
              placeholder="Agregar comentario aqui"
              className='p-1 border rounded w-full'
            />
            <div className='flex mt-4 mb-2'>
              <button onClick={handleReplySubmit} className='py-2 px-4 bg-blue-600 text-white rounded mr-2'>
                Publicar Respuesta
              </button>
              <button onClick={() => setShowReplyInput(false)} className='py-2 px-4 bg-gray-600 text-white rounded'>
                Cancelar
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Comments;
