import { useMutation, UseMutationOptions } from "react-query";
import { db } from "firebaseConfig/config";
import { doc, runTransaction } from 'firebase/firestore';

interface IVariables {
    articleId: string,
    sharedChannel: string
}

const incrementShareCount = async ({ articleId, sharedChannel }: IVariables) => {
  const articleRef = doc(db, 'articles', articleId);
  return runTransaction(db, async (transaction) => {
    const articleSnapshot = await transaction.get(articleRef);
    if (!articleSnapshot.exists()) {
      // Document doesn't exist, set initial values
      transaction.set(articleRef, { [sharedChannel]: 1, views: 1, reactions: 0  });
    } else {
      const currentCount = articleSnapshot.data()[sharedChannel] || 0;
      transaction.update(articleRef, { [sharedChannel]: currentCount + 1 });
    }
  });
};

export const useIncrementShareCount = (
  options?: UseMutationOptions<void, unknown, IVariables>
) => {
  return useMutation(incrementShareCount, options);
};

export default useIncrementShareCount
