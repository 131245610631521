import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES,  } from "@contentful/rich-text-types";
import { BsFlower1 } from "react-icons/bs";

export const richTextRenderer = (document: any, assetMap?: any) => {
    const Paragraph = ({ children }: any) => <p className="mb-6 text-gray-600 text-lg md:text-justify">{children}</p>;
    const Header = ({ children }: any) => <h1 className="text-2xl text-gray-700 font-semibold mb-1">{children}</h1>;
    const Header2 = ({ children }: any) => <h2 className="text-xl text-gray-700 font-semibold mb-1">{children}</h2>;
    const Header3 = ({ children }: any) => <h3 className="text-lg text-gray-700 font-semibold mb-1">{children}</h3>;
    const Header4 = ({ children }: any) => <h4 className="text-lg text-gray-700 font-semibold mb-1">{children}</h4>;
    const Header5 = ({ children }: any) => <h5 className="text-lg text-gray-700 font-semibold mb-1">{children}</h5>;
    const Header6 = ({ children }: any) => <h6 className="text-lg text-gray-700 font-semibold mb-1">{children}</h6>;
    const ListItem = ({ children }: any) => (
        <div className="flex flex-col items-start justify-start">
            <div className='flex items-start justify-start'>
                <div className="text-xl text-gray-600">
                    <BsFlower1 />
                </div>
                <div className="ml-2 md:text-justify">
                    {children}
                </div>
            </div>
        </div>
    );

    // const QuoteCode = ({ children }: any) => {
    //     return (
    //         <pre className="bg-gray-800 text-red-600 px-6 pb-6 pt-3 text-sm" >
    //             <div className="flex mb-8" >
    //             </div>
    //             {children[0].props.children[0]}
    //         </pre>
    //     )
    // }

    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Paragraph>{children} </Paragraph>,
            [BLOCKS.HEADING_1]: (node: any, children: any) => <Header>{children} </Header>,
            [BLOCKS.HEADING_2]: (node: any, children: any) => <Header2>{children}</Header2>,
            [BLOCKS.HEADING_3]: (node: any, children: any) => <Header3>{children}</Header3>,
            [BLOCKS.HEADING_4]: (node: any, children: any) => <Header4>{children}</Header4>,
            [BLOCKS.HEADING_5]: (node: any, children: any) => <Header5>{children}</Header5>,
            [BLOCKS.HEADING_6]: (node: any, children: any) => <Header6>{children}</Header6>,
            [BLOCKS.LIST_ITEM]: (node: any, children: any) => <ListItem>{children} </ListItem>,
            // [BLOCKS.QUOTE]: (node: any, children: any) => <QuoteCode>{children} </QuoteCode>,
            [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
                const assetId = node.data?.target?.sys?.id;
                const imageUrl = assetMap[assetId] ?? null;
                const title = node.data?.target?.fields?.title ?? 'Asset';
                return imageUrl ? <img src={imageUrl} alt={title} className="embedded-image-class" /> : null;
            },
            [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                console.log('EMBEDDED_ENTRY', { node })
            if (node.data?.target?.fields) {
                const { title, content } = node.data.target.fields;
                return (
                <div>
                    <h3>{title || 'Untitled'}</h3>
                    <p>{content || 'No content'}</p>
                </div>
                );
            }
            return null; // or some placeholder
            },
            [INLINES.EMBEDDED_ENTRY]: (node: any) => {
                console.log('EMBEDDED_ENTRY', { node })
            if (node.data?.target?.fields) {
                const { title } = node.data.target.fields;
                return <span>{title || 'Untitled'}</span>;
            }
            return null; // or some placeholder
            },   
            [INLINES.HYPERLINK]: (node: any, children: any) => {
                return (
                    <span className="inline-flex cursor-pointer" >
                        <a
                            href={node.data.uri}
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue-500" >
                            {children}
                        </a>
                    </span>
                )
            },
        },
        renderMark: {
            [MARKS.BOLD]: (text: any) => <b> {text} </b>,
            // [MARKS.CODE]: (text: any) => {
            //     return (
            //         <>
            //         </>
            //     )
            // }
        }
    };

    return documentToReactComponents(document, options);
};