import React from 'react'
import ResponsiveWrap from "../layout/ResponsiveWrap";
import { Link } from "react-router-dom";

const Footer = () => {
  
  return (
    <div className='bg-gray-900 py-20'>

      <ResponsiveWrap>
        <div className='flex justify-between text-gray-200'>
          <ul className="flex flex-col items-start">
            <Link to="/">
              <li className="cursor-pointer hover:text-gray-400">
                Página Inicial
              </li>
            </Link>
            <Link to="/mission">
              <li className="cursor-pointer hover:text-gray-400">
                Misión y Editorial
              </li>
            </Link>
            <Link to="/who">
              <li className="cursor-pointer hover:text-gray-400">
                Quienes Somos?
              </li>
            </Link>
          </ul>
          <div className='text-xs'>
            © 2024 Contra Revolución | All Rights Reserved
          </div>
        </div>
      </ResponsiveWrap>
    </div>
  )
}

export default Footer