import React from 'react'
import { useState, useEffect } from 'react'
import { ImArrowUp } from "react-icons/im";


const FloatingButton = ({ scrollY }: FloatingButtonProps) => {

    const [scrollPossition, setScrollPosition] = useState(0)
    const [width, setWidth] = useState(0)

    const scrollHandler = () => scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    })


    useEffect(() => {
        setScrollPosition(window.scrollY)
        setWidth(window.innerWidth)
        const scroll = () => setScrollPosition(window.scrollY)
        const resize = () => setWidth(window.innerWidth)

        window.addEventListener("scroll", scroll, false)
        window.addEventListener("resize", resize, false)
        return () => {
            window.removeEventListener("scroll", scroll, false)
            window.removeEventListener("resize", resize, false)
        }
    }, [])


    const tailwindClasses =
        `rounded text-white p-3 outline-none text-xl
     focus:outline-none fixed right-12 bottom-12 transition-opacity bg-gray-700
     ${scrollPossition <= scrollY || width < 768 ? `opacity-0` : `opacity-40`}
    `

    return (
        <button
            type="button"
            className={tailwindClasses}
            onClick={scrollHandler}
            // style={{ backgroundColor: '#854d0e' }}
        >
            <ImArrowUp />
        </button>
    )
}

export default FloatingButton

interface FloatingButtonProps {
    scrollY: number
}