import React from 'react'
import { SuperTitle } from "../";
import Logo from '../svg/Logo'
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <div className="w-full flex flex-col justify-center items-center mt-4">
            <Link to='/'>
                <div className="flex">
                    <SuperTitle title="Contra" />
                    <Logo className="-ml-1"/>
                    <SuperTitle title="Revolución" />
                </div>
            </Link>
        </div>
    )
}

export default Header

