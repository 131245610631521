import React from 'react'
import { TopMenu, ResponsiveWrap, FloatingButton, Header } from '../../components'
import { Divider, Footer } from "components";
import { GiJerusalemCross } from "react-icons/gi";

//TODO: Move this Contentful lazy punk ass
const MissionStatement = () => {

  React.useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <Header />
      <div className="sticky top-0 z-40">
        <TopMenu />
      </div>
      <div className="flex -mt-3">
        <Divider className="-mt-2" />
        <GiJerusalemCross className='text-5xl mx-2 font-bold' />
        <Divider className="-mt-2" />
      </div>

      
      <ResponsiveWrap>
        <div className='w-full md:w-7/12 md:mx-auto mb-72 mt-12'>
          <h2 className='text-2xl font-bold mb-6'>Misión de Contra-Revolución</h2>
          <p className='mb-6'>
            En un mundo donde la información es constantemente filtrada, manipulada y
            censurada por élites, es difícil lograr medios de información que sigan una
            línea editorial que navegue en territorio censurado. Nuestra misión es simple
            pero audaz: exponer la verdad sin adornos y proporcionar una plataforma para
            voces críticas que serían censuradas en plataformas más oficialistas.
          </p>
          <p className='mb-6'>
            Contra-Revolución es una publicación comprometida con la transparencia y la
            veracidad. Creemos que la sociedad merece acceso a información auténtica y sin
            censura. Nuestros artículos se centran en una amplia gama de temas, incluyendo
            política, ideología, sociedad, finanzas, cultura, historia, religión y otros.
          </p>
          <p className='mb-6'>
            Nuestra revista se erige como un refugio para aquellos que buscan entender la
            realidad desde diferentes ángulos, sin los filtros impuestos por intereses.
            En Contra-Revolución, no tememos abordar temas controvertidos ni desafiar las
            narrativas predominantes. Cada artículo es una invitación a cuestionar, analizar
            y reflexionar críticamente sobre los problemas que enfrentamos como sociedad.
          </p>
          <h2 className='text-2xl font-bold mt-12 mb-6'>Línea Editorial de Contra-Revolución</h2>
          <ul className='list-disc list-inside mb-6'>
            <li className="mb-2">
              <strong>Propósito y Enfoque Editorial:</strong>
              Somos un grupo que busca establecer un medio de comunicación
              independiente de influencias directas o indirectas de organizaciones
              políticas o estatales pertenecientes a ningún gobierno.
            </li>
            <li className="mb-2">
              <strong>Criterios de Selección de Contenidos:</strong>
              Seleccionamos artículos y formatos que se alineen con nuestros principios
              editoriales, asegurando que cada publicación refleje nuestros valores y
              objetivos. Los contenidos deben ser originales, bien investigados y aportar
              una perspectiva crítica y honesta.
            </li>
            <li className="mb-2">
              <strong>Proceso de Revisión:</strong>
              Nuestros autores tienen la autoridad intelectual sobre sus publicaciones
              a la hora de postearlas y revisarlas. Nuestro proceso de revisión no se basa
              en la revisión de artículos específicos sino en la interpretación agregada
              del conjunto de obras de nuestros autores.
            </li>
            <li className="mb-2">
              <strong>Ética y Transparencia:</strong> Nos guiamos por principios éticos que
              incluyen la integridad, la precisión y la responsabilidad en la producción de
              contenidos. Mantenemos transparencia en la divulgación de fuentes y en la
              declaración de posibles conflictos de interés.
            </li>
            <li className="mb-2">
              <strong>Derechos de Autor y Permisos:</strong>
              Respetamos la propiedad intelectual y nos aseguramos de obtener los permisos
              necesarios para el uso de materiales con derechos de autor. Atribuimos correctamente
              las fuentes y promovemos el respeto por el trabajo de otros. Todas las imágenes
              en nuestro sitio digital son de uso público o somos titulares de su propiedad.
            </li>
            <li className="mb-2">
              <strong>Correcciones y Retractaciones:</strong>
              Tenemos protocolos establecidos para corregir errores y publicar retractaciones
              cuando sea necesario, asegurando la precisión y la credibilidad de nuestros contenidos.
            </li>
            <li className="mb-2">
              <strong>Contribuciones y Participación:</strong>
              Invitamos a colaboradores externos a presentar artículos y opiniones, proporcionando
              directrices claras para la presentación de contenidos. Fomentamos la participación
              de nuestros lectores, incluyendo la publicación de cartas al editor y comentarios.
            </li>
            <li className="mb-2">
              <strong>Independencia Editorial:</strong>
              Mantenemos la independencia del equipo
              editorial respecto a influencias externas, incluyendo anunciantes o patrocinadores,
              para asegurar que nuestros contenidos sean imparciales y veraces.
            </li>
            <li className="mb-2">
              <strong>Legal:</strong>
              La revista Contra-Revolución inevitablemente comparte la
              titularidad de toda responsabilidad legal que pueda crearse por la publicación de un
              artículo, por lo que se reserva el derecho de publicación de los mismos.
            </li>
          </ul>
        </div>
      </ResponsiveWrap>
      
      <Footer />
      <FloatingButton scrollY={500} />
    </React.Fragment>
  )
}

export default MissionStatement
