import React, {
  // useState, useRef, useEffect, MouseEvent
} from 'react';
import { Link } from "react-router-dom";


const TopMenu = () => {
  // const [showDropdown, setShowDropdown] = useState(false);
  // const dropdownRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
  //       setShowDropdown(false);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside as any);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside as any);
  //   };
  // }, []);

  return (
    <div className="bg-white w-full py-4 hidden md:block">
      <div className="w-full flex justify-center items-center">
        <ul className="flex space-x-4 text-gray-300 text-xs">
          <Link to="/fe">
            <li className="text-gray-800 cursor-pointer hover:text-pink-600">
              FÉ
            </li>
          </Link>
          <li>|</li>
          <Link to="/finanzas">
            <li className="text-gray-800 cursor-pointer hover:text-pink-600">
              FINANZAS
            </li>
          </Link>
          <li>|</li>
          <Link to="/politica">
            <li className="text-gray-800 cursor-pointer hover:text-pink-600">
              POLÍTICA
            </li>
          </Link>
          <li>|</li>
          <Link to="/sociedad">
            <li className="text-gray-800 cursor-pointer hover:text-pink-600">
              SOCIEDAD
            </li>
          </Link>
          <li>|</li>
          <Link to="/tecnologia">
            <li className="text-gray-800 cursor-pointer hover:text-pink-600">
              TECNOLOGÍA
            </li>
          </Link>
          <li>|</li>
          <Link to="/historia">
            <li className="text-gray-800 cursor-pointer hover:text-pink-600">
              HISTORIA Y TRADICIÓN
            </li>
          </Link>

          {/* <li>|</li> */}


          {/*  Dropdown over menu  */}

          {/* <li className="relative">
            <div
              className="text-gray-800 cursor-pointer hover:text-blue-500"
              onMouseEnter={() => setShowDropdown(true)}
              >
              AUTORES
            </div>
            {showDropdown && (
              <div
                className="absolute mt-2 bg-white rounded shadow-lg z-10"
                onMouseEnter={() => setShowDropdown(true)}
                onMouseLeave={() => setShowDropdown(false)}
                ref={dropdownRef} // Attach the ref to the dropdown menu
              >
                <ul className="py-2 w-52 border border-gray-200 shadow-2xl">
                  {Tags.map((tag, index) => (
                    <Link to={`/${tag}`} key={tag + index}>
                      <li className="px-4 py-2 text-gray-800 cursor-pointer hover:text-yellow-600">
                        {tag}
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
            )}
          </li> */}
        </ul>
      </div>
    </div>
  )
}

export default TopMenu;
