import React from 'react'
import { formatDistanceToNow } from "date-fns";
// import { translator } from "helpers/formatDistanceToNowTranslator";
import { TruncateText } from "../";

const PostCardSm = ({
    image,
    title,
    publishedDate,
    author
}: PostCardProps) => {
    return (
        <div className="shadow-xl border-r-4 border-pink-600 w-full mb-4 h-auto">
            <div className="flex">
                <div className="flex-1">
                    <img src={image} alt="article image" />
                </div>

                <div className="flex-1 flex flex-col justify-between p-3">
                    <h1 className="text-xs sm:text-lg font-semibold text-gray-700">
                        <TruncateText>
                            {title}
                        </TruncateText>
                    </h1>
                    <div className='w-full flex justify-between'>
                        <h1 className="text-xs text-gray-500">By {author}</h1>
                        {/* <h1 className="text-xs text-gray-500">{translator(formatDistanceToNow(new Date(publishedDate)))}</h1> */}
                        <h1 className="text-xs text-gray-500 hidden md:block">{formatDistanceToNow(new Date(publishedDate))} ago.</h1>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PostCardSm


interface PostCardProps {
    image: string
    title: string
    publishedDate?: any
    author?: string | null
}

