import React from 'react'
import { Link } from "react-router-dom";
import { Title, RenderIf, PostCardSkeletonCard, PostCard } from "../../../components";

interface HorizontalMapProps {
    data: any[]
}

const HorizontalMap = ({ data }: HorizontalMapProps) => {
    return (
        <>
            <div className="mb-8">
                <Title>ARTÍCULOS RECIENTES</Title>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-3">
                <RenderIf isTrue={!data?.length}>
                    {[...new Array(4).fill('*')].map((e, i) => (
                        <PostCardSkeletonCard key={e + i} />
                    ))}
                </RenderIf>
                {data?.slice(4)?.map((post) => (
                    <Link
                        to={`./post/${post.sys.id}`}
                        key={post.title + post.sys.id}
                        className="mb-2 inline-block"
                    >
                        <PostCard {...post} />
                    </Link>
                ))}
            </div>
        </>
    )
}

export default HorizontalMap
